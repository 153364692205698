.app {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #1a1a2e;
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: #2b2b3d;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.logo a {
  color: #fff;
  text-decoration: none;
}

.menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu li {
  margin-left: 20px;
}

.menu a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.menu a:hover {
  text-decoration: underline;
}

.main-content {
  flex: 1;
  padding: 20px;
  transition: opacity 0.5s ease-in-out;
}

.hero {
  text-align: center;
  padding: 50px 20px;
  background-color: #2b2b3d;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.hero h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #f0f0f0;
}

.hero p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #d0d0d0;
}

.cta-button {
  background-color: #6ab6ff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.cta-button a {
  color: #fff;
  text-decoration: none;
}

.cta-button:hover {
  background-color: #509ed8;
  transform: scale(1.05);
}

.features {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.feature {
  background-color: #2b2b3d;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 30%;
  text-align: center;
}

.feature h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #f0f0f0;
}

.feature p {
  font-size: 16px;
  color: #d0d0d0;
}

.footer {
  background-color: #2b2b3d;
  color: #fff;
  text-align: center;
  padding: 10px;
}
