.donate {
    text-align: center;
    padding: 50px 20px;
    background-color: #fff;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
  }
  
  .donate h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .donate p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .amount-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .amount-buttons button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .amount-buttons button:hover {
    background-color: #0056b3;
  }
  
  .amount-buttons input {
    padding: 10px;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-left: 10px;
  }
  
  .donate-buttons {
    display: flex;
    justify-content: center;
  }
  
  .donate-buttons a {
    text-decoration: none;
    margin: 0 10px;
  }
  
  .paypal-button, .cashapp-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .paypal-button:hover, .cashapp-button:hover {
    background-color: #0056b3;
  }
  