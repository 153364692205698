.app {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #1a1a2e;
    color: #e0e0e0;
}

.download-page {
    text-align: center;
    padding: 50px 20px;
    background-color: #1a1a2e;
    color: #e0e0e0;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    max-width: 800px;
}

.download-page h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #ffffff;
}

.download-page h3 {
    font-size: 20px;
    margin: 25px 0 10px;
    color: #b0b0b0;
}

.top-box {
    background-color: #2b2b3d;
    border: 1px solid #444466;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.top-box h1 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #f0f0f0;
}

.top-box p {
    font-size: 1.1rem;
    color: #d0d0d0;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 20px 0;
}

.primary-button {
    background-color: #6ab6ff;
    color: #ffffff;
    border: none;
    padding: 10px 18px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
}

.primary-button:hover {
    background-color: #509ed8;
    transform: scale(1.05);
}

.secondary-button {
    background-color: #28a745;
    color: #ffffff;
    border: none;
    padding: 10px 18px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
}

.secondary-button:hover {
    background-color: #218838;
    transform: scale(1.05);
}

.version-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.version-list li {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.version-list button {
    background-color: #6ab6ff;
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s;
}

.version-list button:hover {
    background-color: #509ed8;
    transform: scale(1.05);
}

.changelog-box {
    background-color: #2b2b3d;
    border: 1px solid #444466;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    color: #e0e0e0;
    text-align: left;
}

.changelog-box h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #b0b0b0;
}

.changelog-content {
    padding: 15px;
    background-color: #1e1e2f;
    border: 1px solid #3e3e5e;
    border-radius: 8px;
    line-height: 1.6;
    color: #cccccc;
}

.changelog-content p {
    margin: 0;
}


.navbar {
    background-color: #d3d3d3;
    color: #1a1a2e;
    padding: 10px;
    border-bottom: 1px solid #b0b0b0;
}

.navbar a {
    color: #1a1a2e;
    text-decoration: none;
    font-weight: bold;
}

.navbar a:hover {
    text-decoration: underline;
}

@media (max-width: 600px) {
    .download-page {
        padding: 20px 10px;
    }

    .download-page h1 {
        font-size: 28px;
    }

    .latest-version h2 {
        font-size: 18px;
    }

    .download-page h3 {
        font-size: 16px;
    }

    .primary-button, .secondary-button {
        padding: 8px 14px;
    }
}
