.app {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #1a1a2e;
  color: #e0e0e0;
}

.thank-you {
  padding: 50px 20px;
  background-color: #1a1a2e;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  text-align: center;
}

.thank-you h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #ffffff;
}

.thank-you p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #d0d0d0;
}

.download-link {
  font-size: 14px;
  color: #6ab6ff;
}

.download-link a {
  color: #6ab6ff;
  text-decoration: none;
}

.download-link a:hover {
  text-decoration: underline;
}

.donate-section {
  margin-top: 40px;
  padding: 20px;
  background-color: #2b2b3d;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.donate-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffffff;
}

.amount-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.amount-buttons button {
  background-color: #6ab6ff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.amount-buttons button:hover {
  background-color: #509ed8;
}

.amount-buttons input {
  padding: 10px;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid #444466;
  margin-left: 10px;
  background-color: #2b2b3d;
  color: #ffffff;
}

.donate-buttons {
  display: flex;
  justify-content: center;
}

.donate-button {
  background-color: #28a745;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  text-decoration: none;
  margin: 0 10px;
  cursor: pointer;
}

.donate-button:hover {
  background-color: #218838;
}

.markdown-container {
  margin-top: 30px;
  padding: 20px;
  background-color: #2b2b3d;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  text-align: left;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.markdown-container h3 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #ffffff;
}

.markdown-content {
  overflow-wrap: break-word;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #cccccc;
}

.click-here {
  font-size: 16px;
  color: #6ab6ff;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease, transform 0.3s ease;
}

.click-here:hover {
  color: #509ed8;
  text-decoration: underline;
  transform: translateY(-5px);
}

.click-here:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #6ab6ff;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.click-here:hover:before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbar {
  background-color: #2b2b3d;
  color: #e0e0e0;
  padding: 10px;
  border-bottom: 1px solid #444466;
}

.navbar a {
  color: #e0e0e0;
  text-decoration: none;
  font-weight: bold;
}

.navbar a:hover {
  text-decoration: underline;
}
