.downloads {
  text-align: center;
  padding: 50px 20px;
  background-color: #2b2b3d;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 800px;
}

.downloads h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #f0f0f0;
}

.downloads p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #d0d0d0;
}

.download-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-item {
  background-color: #2b2b3d;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  text-align: left;
}

.download-item h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #f0f0f0;
}

.download-item p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #d0d0d0;
}

.download-button {
  background-color: #6ab6ff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.2s;
}

.download-button:hover {
  background-color: #509ed8;
  transform: scale(1.05);
}
